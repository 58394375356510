export const DASHBOARD = '/';

export const LOGIN = '/login';
export const REGISTER = '/register';
export const RESET_PASSWORD = '/reset-password';
export const SET_PASSWORD = '/set-password';

export const USER = '/user';
export const EDIT_USER = '/edit-user/:id'
export const createUserEditRoute = id => `/edit-user/${id}`;
export const ORDER = '/order';
export const ORDERINFO = '/order-info/:id';
export const createOrderRoute = id => `/order-info/${id}`;

export const INVENTORY = '/inventory';
export const CATALOG = 'https://www.canva.com/design/DAF41qCIotk/POSHmZ5IqEzHN0yaeoAgPQ/view?utm_content=DAF41qCIotk&utm_campaign=designshare&utm_medium=link&utm_source=viewer';

export const CHANGE_PASSWORD = '/change-password';

export const EDIT_PROFILE = '/edit-profile';

export const HELP = '/help';
