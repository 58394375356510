import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchError } from './commonSlice';

const initialState = {
  list: [],
  perPage: '',
  total: '',
  loading: true,
  errorMessage: '',
};

export const getOrderList = createAsyncThunk('get-order', async (data, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_V2}order/list`, { params: data });
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const editOrder = createAsyncThunk('edit-order', async (id, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_V2}order/${id}`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.orders;
        state.perPage = action?.payload?.data?.data?.records_returned;
        state.total = action?.payload?.data?.data?.total_records;
        state.errorMessage = '';
      })
      .addCase(getOrderList.rejected, (state, action) => {
        state.loading = false;
        state.list = '';
        state.perPage = '';
        state.total = '';
        state.errorMessage = action.error?.message;
      });
  },
});

export default orderSlice.reducer;
