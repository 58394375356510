import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchError } from './commonSlice';

const initialState = {
  list: [],
  loading: false,
};

export const getInventoryList = createAsyncThunk('get-inventory', async ({ search, token }, { dispatch }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL_V2}inventory/list`,
      { params: { search } },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error?.response?.data?.responseMessage;
  }
});

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryList?.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.inventory_items;
      })
      .addCase(getInventoryList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default inventorySlice.reducer;
