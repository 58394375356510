import React from 'react';
import { Grid, Typography } from '@mui/material';

const Help = () => {
  return (
    <Grid container spacing={2} sx={{ mt: 0, pt: 0 }}>
      <Grid item xs={12}>
        <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
          If you’re struggling to find the information you need, please drop us an email on <a style={{color:'black'}} href="mailto:someone@example.com">info@miamerchandise.com</a>, or reach out to your account manager directly.
        </Typography>
        {/* <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
          If you can't find the information you're looking for or need further assistance, please don't hesitate to contact
          our support team. You can reach us via:
        </Typography>
        <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
          Email: portalsignup@miamerchandise.com
        </Typography>
        <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
          Our team is here to help you!
        </Typography>
        <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
          Thank you for choosing MIA Portal.
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export default Help;
