import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { USER } from '@jumbo/utils/constants/routes';

const menus = [
  {
    label: 'Home',
    type: 'section',
    children: [
      {
        uri: USER,
        label: 'Users',
        type: 'nav-item',
        icon: <PersonIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
