import React, { lazy } from 'react';

import {
  CHANGE_PASSWORD,
  DASHBOARD,
  EDIT_PROFILE,
  EDIT_USER,
  HELP,
  INVENTORY,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  SET_PASSWORD,
  USER,
  ORDER,
  ORDERINFO
} from '@jumbo/utils/constants/routes';
import Help from 'app/pages/help';
import Order from 'app/pages/order';
import OrderInfo from 'app/pages/order/OrderInfo';
const Page = lazy(() => import('@jumbo/shared/Page'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Login = lazy(() => import('app/pages/login'));
const Error404 = lazy(() => import('app/pages/pagenotfound'));
const Home = lazy(() => import('app/pages/home/Home'));
const Register = lazy(() => import('app/pages/user/Register'));
const ResetPassword = lazy(() => import('app/pages/forgotPassword/ResetPassword'));
const SetPassword = lazy(() => import('app/pages/forgotPassword/SetPassword'));
const ChangePassword = lazy(() => import('app/pages/user/ChangePassword'));
const User = lazy(() => import('app/pages/user'));
const Inventory = lazy(() => import('app/pages/inventory'));
const EditUser = lazy(() => import('app/pages/user/EditUser'));
const EditProfile = lazy(() => import('app/pages/user/EditProfile'));

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: LOGIN,
    element: <Page component={Login} layout={'solo-page'} disableSmLogin={true} />,
  },
  {
    path: REGISTER,
    element: <Page component={Register} layout={'solo-page'} disableSmLogin={true} />,
  },
  {
    path: RESET_PASSWORD,
    element: <Page component={ResetPassword} layout={'solo-page'} disableSmLogin={true} />,
  },
  {
    path: SET_PASSWORD,
    element: <Page component={SetPassword} layout={'solo-page'} disableSmLogin={true} />,
  },
  {
    path: '*',
    element: <Page component={Error404} layout={'solo-page'} disableSmLogin={true} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAllUsers = [
  {
    path: CHANGE_PASSWORD,
    element: (
      <PrivateRoute>
        <Page component={ChangePassword} />
      </PrivateRoute>
    ),
  },
  {
    path: EDIT_PROFILE,
    element: (
      <PrivateRoute>
        <Page component={EditProfile} />
      </PrivateRoute>
    ),
  },
];

const routesForClient = [
  {
    path: DASHBOARD,
    element: (
      <PrivateRoute>
        <Page component={Home} />
      </PrivateRoute>
    ),
  },
  {
    path: INVENTORY,
    element: (
      <PrivateRoute>
        <Page component={Inventory} />
      </PrivateRoute>
    ),
  },
  {
    path: ORDER,
    element: (
      <PrivateRoute>
        <Page component={Order} />
      </PrivateRoute>
    ),
  },
  {
    path: ORDERINFO,
    element: (
      <PrivateRoute>
        <Page component={OrderInfo} />
      </PrivateRoute>
    ),
  },
  {
    path: HELP,
    element: (
      <PrivateRoute>
        <Page component={Help} />
      </PrivateRoute>
    ),
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForAdmin = [
  {
    path: USER,
    element: (
      <PrivateRoute>
        <Page component={User} />
      </PrivateRoute>
    ),
  },
  {
    path: EDIT_USER,
    element: (
      <PrivateRoute>
        <Page component={EditUser} />
      </PrivateRoute>
    ),
  },
];

const routes = [...routesForPublic, ...routesForAllUsers, ...routesForAdmin, ...routesForClient];

export { routes as default, routesForPublic, routesForAllUsers, routesForAdmin, routesForClient };
