import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, MoreHoriz } from '@mui/icons-material';
import CardComponent from '@jumbo/components/common/Card';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import CustomTable from '@jumbo/components/CustomeTable';
import { createOrderRoute } from '@jumbo/utils/constants/routes';
import { getOrderList } from 'app/redux/slice/orderSlice';
import Loader from '@jumbo/components/common/Loader';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { LoadingButton } from '@mui/lab';

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state?.order);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(process.env.REACT_APP_PER_PAGE ?? 10);
  const [loading, setLoading] = useState(true);
  const [isSubmitSearch, setSubmitSearch] = useState(false);
  const [search, setSearch] = useState('');

  const handleItemAction = (menuItem, record) => {
    switch (menuItem.action) {
      case 'view':
        navigate(createOrderRoute(record?.id));
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      label: 'Order Id',
      key: 'source_id',
      sort: true,
    },
    {
      label: 'Order Name',
      render: (elm) => <> {elm?.order_items[0]?.name}</>,
    },
    {
      label: 'Tracking Number',
      render: (elm) => <> {elm?.order_shipments[0]?.tracking_number ? <Link to={elm?.order_shipments[0]?.tracking_url} target="_blank">{elm?.order_shipments[0]?.tracking_number}</Link> : '-'}</>,
    },
    {
      label: 'Status',
      key: 'orderStatus',
      sort: true,
    },
    {
      label: 'Customer Name',
      render: (elm) => <> {`${elm?.customer?.first_name} ${elm?.customer?.last_name}`}</>,
    },
    {
      label: 'Action',
      render: (elm) => {
        const menuItems = [{ icon: <Visibility />, title: 'View', action: 'view' }];
        return (
          <JumboDdMenu
            icon={<MoreHoriz />}
            menuItems={menuItems}
            onClickCallback={(event) => handleItemAction(event, elm)}
          />
        );
      },
    },
  ];

  useEffect(async () => {
    const value = { offset: page, limit: rowsPerPage };
    if (search) {
      value.search = search;
    }
    await dispatch(getOrderList(value));
    setLoading(false);
  }, [page, rowsPerPage, dispatch, isSubmitSearch]);

  return (
    <>
      <Card sx={{ display: 'flex', mb: 3.5 }}>
        <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10}>
                <TextField fullWidth size="small" label="Search" onChange={(evt) => setSearch(evt.target.value)} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => setSubmitSearch(!isSubmitSearch)}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </CardContent>
        </Div>
      </Card>
      {orderData.loading ? (
        <Loader />
      ) : (
        <>
          <CardComponent title={`List of Orders`}>
            <CustomTable
              columns={columns}
              record={orderData?.list || []}
              setPage={setPage}
              page={page}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              total={orderData?.total}
            />
          </CardComponent>
        </>
      )}
    </>
  );
};

export default Order;
