import { AdminRole, Clientrole } from '@jumbo/utils/constants/userTypes';

export const userType = () => {
  let obj = { isAdmin: false, isClient: false };
  const result = JSON.parse(localStorage.getItem('role'));
  if (result === AdminRole) {
    obj.isAdmin = true;
  } else if (result === Clientrole) {
    obj.isClient = true;
  }
  return obj;
};
