import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '@jumbo/components/common/Loader';
import { Link, useParams } from 'react-router-dom';
import { editOrder } from 'app/redux/slice/orderSlice';
import CardComponent from '@jumbo/components/common/Card';
import { Grid, Typography } from '@mui/material';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import moment from 'moment';

const OrderInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    dispatch(editOrder(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          setOrderData(res?.data?.data?.order);
        }
        setLoading(false);
      });
  }, []);
  function getImageExtension(url) {
    url = url.split('?')[0];
    const extension = url.split('.').pop().toLowerCase();
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    if (validExtensions.includes(extension)) {
      return extension;
    } else {
      return null;
    }
  }
  const commonImgFun = (imgURL) => {
    if (getImageExtension(imgURL)) {
      return imgURL.replaceAll(process.env.REACT_APP_DROPBOX_START_POINT, process.env.REACT_APP_DROPBOX_START_NEW_POINT);
    } else {
      if (imgURL.includes('drive.google.com')) {
        const params = new URLSearchParams(new URL(imgURL).search);
        const fileId = params.get('id');
        return `https://lh3.googleusercontent.com/d/${fileId}`;
      }
      return false;
    }
  };
  const handleReplace = (stringValue) => {
    let imageUrl = `${ASSET_IMAGES}/no-img.png`;
    if (stringValue) {
      const { image, print_url, print_url_1, Print_url, Image } = stringValue;
      if (image) {
        imageUrl = commonImgFun(image);
      } else if (print_url) {
        imageUrl = commonImgFun(print_url);
      } else if (print_url_1) {
        imageUrl = commonImgFun(print_url_1);
      } else if (Print_url) {
        imageUrl = commonImgFun(Print_url);
      } else if (Image) {
        imageUrl = commonImgFun(Image);
      }
      if (!imageUrl) imageUrl = `${ASSET_IMAGES}/no-img.png`;
    }
    return imageUrl;
  };
  const imgUrl = handleReplace(orderData?.order_items?.[0]?.metadata);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CardComponent title={`Order Info ${orderData?.id}`}>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgUrl} alt={orderData?.id} height={200} style={{ maxWidth: '350px' }} />
              </Grid>
              <Grid item xs={4} sx={{ justifyContent: 'center' }}>
                <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '20px' }}>
                  {orderData?.order_items?.[0]?.name}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Order ID : ${orderData?.source_id}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Order Date : ${moment(orderData?.date_added).format('DD/MM/YYYY')}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`First Name : ${orderData?.customer?.first_name}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Last Name : ${orderData?.customer?.last_name}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Status : ${orderData?.orderStatus}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Tracking Number : ${orderData?.order_shipments?.[0]?.tracking_number ? '': '-'}`}
                </Typography>{orderData?.order_shipments[0]?.tracking_number ? <Link to={orderData?.order_shipments[0]?.tracking_url} target="_blank">{orderData?.order_shipments[0]?.tracking_number}</Link> : '-'}
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Price : ${orderData?.order_items?.[0]?.price}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`Product SKU : ${orderData?.order_items?.[0]?.metadata?.print_sku ?? '-'}`}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ justifyContent: 'center' }}>
                <Typography variant={'h1'} sx={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '20px' }}>
                  Delivery Address
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {`${orderData?.shipping?.first_name} ${orderData?.shipping?.last_name}`}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {orderData?.shipping?.address1}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {orderData?.shipping?.address2}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {orderData?.shipping?.postal_code}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {orderData?.shipping?.city}
                </Typography>
                <Typography variant={'h3'} sx={{ fontSize: '14px' }}>
                  {orderData?.shipping?.country}
                </Typography>
              </Grid>
            </Grid>
          </CardComponent>
        </>
      )}
    </>
  );
};

export default OrderInfo;
