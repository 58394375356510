import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice from '../slice/authSlice';
import userSlice from '../slice/userSlice';
import inventorySlice from '../slice/inventorySlice';
import dashboardSlice from '../slice/dashboardSlice';
import orderSlice from '../slice/orderSlice';
import commonSlice from '../slice/commonSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  common: commonSlice,
  user: userSlice,
  inventory: inventorySlice,
  dashboard: dashboardSlice,
  order: orderSlice,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

export const persister = persistStore(store);
