export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  export function getComparator(order, orderBy, selectedColumn) {
    let ans = 0;
    if (selectedColumn?.length && selectedColumn[0]?.sorter?.compare) {
      ans = order === 'desc'
          ? (a, b) => selectedColumn[0]?.sorter?.compare(a, b)
          : (a, b) => -selectedColumn[0]?.sorter?.compare(a, b);
    } else {
      ans = order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }
    return ans;
  }
  
  
  export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  