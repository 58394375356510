import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, IconButton } from '@mui/material';

import JumboApp from '@jumbo/components/JumboApp';
import JumboDialog from '@jumbo/components/JumboDialog';
import JumboDialogProvider from '@jumbo/components/JumboDialog/JumboDialogProvider';
import JumboTheme from '@jumbo/components/JumboTheme';
import JumboRTL from '@jumbo/JumboRTL/JumboRTL';
import Div from '@jumbo/shared/Div';
import { DASHBOARD, LOGIN, USER } from '@jumbo/utils/constants/routes';
import AppLayout from './AppLayout';
import AppRoutes from './AppRoutes';
import AppProvider from './AppProvider';
import { config } from './config/main';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CancelIcon sx={{ color: '#ffffff' }} />
    </IconButton>
  );
}

function App() {
  const token = useSelector((state) => state?.auth?.token);
  const user = useSelector((state) => state?.auth?.loggedUser);
  const { pathname } = useLocation();

  if (token) {
    if (pathname === LOGIN) {
      if (user?.roleType === 1) {
        return <Navigate to={USER} />;
      } else {
        return <Navigate to={DASHBOARD} />;
      }
    }
    if (user?.roleType === 1 && pathname === DASHBOARD) {
      return <Navigate to={USER} />;
    }
  }

  return (
    <AppProvider>
      <JumboApp activeLayout={config.defaultLayout}>
        <JumboTheme init={config.theme}>
          <JumboRTL>
            <JumboDialogProvider>
              <JumboDialog />
              <SnackbarProvider
                hideIconVariant
                action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                maxSnack={3}
                autoHideDuration={4000}>
                <AppLayout>
                  <Suspense
                    fallback={
                      <Div
                        sx={{
                          display: 'flex',
                          minWidth: 0,
                          alignItems: 'center',
                          alignContent: 'center',
                          height: '100%',
                        }}>
                        <CircularProgress sx={{ m: '-40px auto 0' }} />
                      </Div>
                    }>
                    <AppRoutes />
                  </Suspense>
                </AppLayout>
              </SnackbarProvider>
            </JumboDialogProvider>
          </JumboRTL>
        </JumboTheme>
      </JumboApp>
    </AppProvider>
  );
}

export default App;
