import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchError } from './commonSlice';

const initialState = {
  loading: false,
  errorMessage: '',
  loggedUser: {},
  token: '',
};

export const registerUser = createAsyncThunk('client/register', async (data, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}client/register`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const loginUser = createAsyncThunk('authenticate/login', async (credentials, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}authenticate/login`, credentials);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const verifyUser = createAsyncThunk('test', async (_, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_ORDER_DESK_BASE_URL}test`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const logoutUser = createAsyncThunk('authenticate/logout', async (id, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}authenticate/logout/${id}`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const resetPassword = createAsyncThunk('authenticate/reset-password', async (data, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}authenticate/reset-password`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const setPassword = createAsyncThunk('authenticate/set-password', async (data, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}authenticate/set-password`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const changePassword = createAsyncThunk('authenticate/change-password', async (data, { dispatch }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}authenticate/change-password`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const updateProfile = createAsyncThunk('update-user', async ({ id, data }, { dispatch }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}admin/client/${id}`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = '';
        state.loggedUser = action?.payload?.data?.data?.user;
        state.token = action?.payload?.data?.data?.token;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(verifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUser.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = '';
        state.loggedUser = {};
        state.token = '';
        state.email = '';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(setPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = '';
        state.loggedUser = action?.payload?.data?.data?.updateClient;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });


  },
});

export default authSlice.reducer;
