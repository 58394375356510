import React from 'react';
import Div from '@jumbo/shared/Div';
import { Link } from 'react-router-dom';
import { ASSET_IMAGES } from '../../utils/constants/paths';
import { DASHBOARD } from '@jumbo/utils/constants/routes';

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <Link to={DASHBOARD}>
        {!mini ? (
          <img src={mode === 'light' ? `${ASSET_IMAGES}/pink.png` : `${ASSET_IMAGES}/MIA-WHITE-LOGO.png`} alt="Jumbo React"/>
        ) : (
          <img
            src={mode === 'light' ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`}
            alt="Jumbo React"
          />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
