import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchError } from './commonSlice';

const initialState = {
  list: [],
  perPage: '',
  total: '',
  loading: false,
  errorMessage: '',
};

export const getUserList = createAsyncThunk('get-user', async (data, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/client/list`, { params: data });
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const editUser = createAsyncThunk('edit-user', async (id, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/client/${id}`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const updateUser = createAsyncThunk('update-user', async ({ id, data }, { dispatch }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}admin/client/${id}`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const deleteUser = createAsyncThunk('delete-user', async (id, { dispatch }) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}admin/client/${id}`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const storeConnect = createAsyncThunk('store-connect', async ({ id, data }, { dispatch }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}admin/client/store-connect/${id}`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

export const statusUpdate = createAsyncThunk('status-update', async ({ id, data }, { dispatch }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}admin/client/status-update/${id}`, data);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.clientList;
        state.perPage = action?.payload?.data?.data?.perPage;
        state.total = action?.payload?.data?.data?.total;
        state.errorMessage = '';
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.loading = false;
        state.list = '';
        state.perPage = '';
        state.total = '';
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(editUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUser.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });

    builder
      .addCase(storeConnect.pending, (state) => {
        state.loading = true;
      })
      .addCase(storeConnect.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(storeConnect.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error?.message;
      });
  },
});

export default userSlice.reducer;
