import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchError } from './commonSlice';

const initialState = {
  data: {},
  errorMessage: '',
  loading: true,
};

export const getDashboardList = createAsyncThunk('get-dashboard', async (_, { dispatch }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}client/dashboard`);
    return response;
  } catch (error) {
    dispatch(fetchError(error.response.data?.responseMessage));
    return error.response.data?.responseMessage;
  }
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload?.data?.data;
        state.errorMessage = '';
      })
      .addCase(getDashboardList.rejected, (state, action) => {
        state.loading = false;
        state.data = {};
        state.errorMessage = action.error?.message;
      });
  },
});

export default dashboardSlice.reducer;
