import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ListItemIcon, ListItemText, ThemeProvider, Typography, Divider, List, ListItemButton, Avatar } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { logoutUser } from 'app/redux/slice/authSlice';
import { LOGIN } from '@jumbo/utils/constants/routes';

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state?.auth?.loggedUser);

  const user = JSON.parse(localStorage.getItem('User'));

  const onLogout = () => {
    dispatch(logoutUser(authUser?._id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.removeItem('role');
          localStorage.clear();
          navigate(LOGIN);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover triggerButton={<Avatar src={''} sizes={'small'} sx={{ boxShadow: 25, cursor: 'pointer' }} />}>
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
          }}>
          <Avatar src={''} alt={`${user?.first_name} ${user?.last_name}`} sx={{ width: 60, height: 60, mb: 2 }} />
          <Typography variant={'h5'}>
            {authUser?.firstName} {authUser?.lastName}
          </Typography>
          <Typography variant={'body1'} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={() => navigate('/edit-profile')}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/change-password')}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <VpnKeyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
