import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  TableSortLabel,
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';
import JumboListNoDataPlaceholder from '../JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import { getComparator, stableSort } from './tableHelper';

const CustomTable = ({
  record,
  columns,
  isShowPagination,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isInventory,
  total,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  let selectedColumn = null;
  if (orderBy) {
    selectedColumn = columns.filter((data) => data.id === orderBy && data);
  }

  let rec = stableSort(record, getComparator(order, orderBy, selectedColumn));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const data = isInventory ? rec.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rec;
  return (
    <>
      {rec?.length === 0 ? (
        <JumboListNoDataPlaceholder />
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sortDirection={orderBy === column.key ? order : false}
                      key={column.key}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.sort ? (
                        <TableSortLabel
                          active={orderBy === column.key}
                          direction={orderBy === column.key ? order : 'asc'}
                          onClick={createSortHandler(column.key)}>
                          {column.label}
                          {orderBy === column.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column, i) => {
                        return (
                          <TableCell key={i} align={column.align}>
                            {column.render ? column.render(row, index) : row[column?.key]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {isShowPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      )}
    </>
  );
};

CustomTable.propTypes = {
  record: PropTypes.array,
  column: PropTypes.array,
  isShowPagination: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
};

CustomTable.defaultProps = {
  isShowPagination: true,
};

export default CustomTable;
