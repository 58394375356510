import React from 'react';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpIcon from '@mui/icons-material/Help';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { DASHBOARD, HELP, INVENTORY, CATALOG, ORDER } from '@jumbo/utils/constants/routes';

const menus = [
  {
    label: 'Home',
    type: 'section',
    children: [
      {
        uri: DASHBOARD,
        label: 'Dashboard',
        type: 'nav-item',
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: INVENTORY,
        label: 'Inventory',
        type: 'nav-item',
        icon: <InventoryIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ORDER,
        label: 'Orders',
        type: 'nav-item',
        icon: <ShoppingCartIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: CATALOG,
        label: 'Catalog',
        type: 'nav-item',
        icon: <MenuBookIcon sx={{ fontSize: 20 }} />,
        target: '_blank',
      },
      {
        uri: HELP,
        label: 'Help',
        type: 'nav-item',
        icon: <HelpIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
