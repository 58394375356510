import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';


const useStyles = makeStyles({
  decorationNone: {
    textDecoration: 'none',
    marginLeft: '3px',
  },
});

const Footer = () => {
  const classes = useStyles()
  return (
    <Div
      sx={{
        py: 2,
        px: { lg: 6, xs: 4 },
        borderTop: 2,
        borderColor: 'divider',
        bgcolor: 'background.paper',
      }}
    >
      <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant={'body1'} color={'text.primary'}>
          Copyright <Link to="https://miamerchandise.com" target="_blank" rel="noopener noreferrer" className={classes?.decorationNone}>MIA</Link>  © 2024
        </Typography>
      </Div>
    </Div>
  );
};

export default Footer;
