import React, { useEffect } from 'react';

import { routesForPublic, routesForAllUsers, routesForAdmin, routesForClient } from './routes';
import useJumboRoutes from '@jumbo/hooks/useJumboRoutes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userType } from './helpers/userType';
import { logoutUser } from './redux/slice/authSlice';

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state?.auth?.loggedUser);

  let routes = [];
  const { isAdmin } = userType();
  const common = useSelector((state) => state?.common);
  if (isAdmin) {
    routes = [...routes, ...routesForPublic, ...routesForAllUsers, ...routesForAdmin];
  } else {
    routes = [...routes, ...routesForPublic, ...routesForAllUsers, ...routesForClient];
  }
  useEffect(() => {
    if (
      common?.errorMessage === 'Token Expired.' ||
      common?.errorMessage === 'Token not passed.' ||
      common.errorMessage === "Invalid token." ||
      common?.errorMessage === 'Your token has expired!'
    ) {
      dispatch(logoutUser(authUser?._id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            localStorage.clear();
            navigate('/login');
          }
        });
    }
  }, [common, dispatch, navigate]);
  const appRoutes = useJumboRoutes(routes);

  return <React.Fragment>{appRoutes}</React.Fragment>;
};
export default AppRoutes;
